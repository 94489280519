<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <img
          src="@/assets/images/gif/progress.gif"
          class="img-fluid"
          alt="logo"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
export default {
  name: 'VerificationEmail',
  props: {
    token: {
      type: String
    }
  },
  mixins: [errorDialog],
  data () {
    return {
      userToken: this.token
    };
  },
  mounted () {
    this.backendCallback();
  },
  methods: {
    backendCallback () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/user/verification/${this.userToken}`;
      axios
        .get(url)
        .then(() => {
          this.$swal.fire({
            title: this.$t('awesome'),
            text: this.$t('successVerify'),
            confirmButtonColor: '#19AD93'
          });
          this.$router.push({ name: 'social.list' });
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
          this.$router.push({ name: 'auth1.sign-in1' });
        });
    }
  }
};
</script>
